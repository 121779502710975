body {
  font-family: 'Roboto-Regular'!important;
  font-size: 14px!important;
  color: #797979;
  font-weight: 400;
  line-height: 1.6;
}


/*===== BASE =====*/

* {
  margin: 0;
  padding: 0;
}

*,
::before,
::after {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

ul {
  margin: 0!important;
  padding: 0!important;
  list-style: none!important;
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
  display: block;
}

a:hover,
a:focus {
  color: #000000;
  text-decoration: none!important;
  outline: none;
}

/* end base */