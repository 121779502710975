/* VISIONAIRE PAGE */
.form-logos {
  height: 40px;
}
.form-header {
  border-bottom: 1px solid #d5d5d5;
  padding: 15px;
}
#master-sanct {
  color: #612f91;
}
.form-heading {
  margin-top: -32px;
  margin-left: 40px;
  margin-bottom: 10px;
}
.list {
  padding: 15px;
}
.review-btn {
  border-top: 1px solid #d5d5d5;
}
.review-btn a {
  text-decoration: none;
}
.label-text {
  padding-left: 10px;
}
label.custom-checkbox input[type="checkbox"] {
  opacity: 0;
}

label.custom-checkbox input[type="checkbox"] ~ .helping-el {
  background-color: #ffffff;
  border: 2px solid #99a201;
  border-radius: 2px;
  display: inline-block;
  padding: 10px;
  position: relative;
  top: 2px;
}

label.custom-checkbox input[type="checkbox"]:checked ~ .helping-el {
  border: 2px solid #99a201;
  background-color: #99a201;
}

label.custom-checkbox input[type="checkbox"]:checked ~ .helping-el:after {
  color: #ffffff;
  content: "\2714";
  font-size: 20px;
  font-weight: normal;
  left: 2px;
  position: absolute;
  top: -6px;
  transform: rotate(10deg);
}

.meta-cont {
  margin-top: -50px;
  overflow: auto;
}
.viewOptCont {
  position: absolute;
  right: 30px;
  top: 50px;
}
.meta-wheel {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0;
}
.optLink {
  margin-left: 10px;
}
.optIcon {
  width: 20px;
  height: 20px;
  margin: 10px;
  color: #212529;
}
.list a {
  color: #007bff;
}

/* SUB LIST PAGE */

.form-logos {
  height: 40px;
}
.form-header {
  border-bottom: 1px solid #d5d5d5;
  padding: 15px;
}
#master-sanct {
  color: #612f91;
}
.form-heading {
  margin-top: -32px;
  margin-left: 40px;
  margin-bottom: 10px;
}
.list {
  padding: 15px;
}
.review-btn {
  border-top: 1px solid #d5d5d5;
}
.review-btn a {
  text-decoration: none;
}
.label-text {
  padding-left: 10px;
}
label.custom-checkbox input[type="checkbox"] {
  opacity: 0;
}

label.custom-checkbox input[type="checkbox"] ~ .helping-el {
  background-color: #ffffff;
  border: 2px solid #99a201;
  border-radius: 2px;
  display: inline-block;
  padding: 10px;
  position: relative;
  top: 2px;
}

label.custom-checkbox input[type="checkbox"]:checked ~ .helping-el {
  border: 2px solid #99a201;
  background-color: #99a201;
}

label.custom-checkbox input[type="checkbox"]:checked ~ .helping-el:after {
  color: #ffffff;
  content: "\2714";
  font-size: 20px;
  font-weight: normal;
  left: 2px;
  position: absolute;
  top: -6px;
  transform: rotate(10deg);
}
