.scroller{
  display:grid;
  grid-auto-flow: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
}
.scroller-container {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: repeat(6, 1fr);
  overflow: auto;
  gap: 20px;
  width: 100%;
  white-space: break-spaces;
}

.scroller-item {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  width: 210px;
  flex: 0 0 auto;
  cursor: pointer;
}

.arrow {
  color: #9aa200;
  cursor: pointer;
}
