.account-detail {
    // width: 100%;
    // overflow: hidden;

    .banner-secss {
        position: relative;

        img {
            height: 250px !important;
            width: 100%;
            object-fit: cover;
        }

        &::before {
            background-color: #2827278e;
            content: "";
            display: block;
            height: 250px !important;
            position: absolute;
            width: 100%;
        }

        .content-sec {
            position: absolute;
            width: unset !important;
            color: #fff;
            top: 125px !important;
            left: 0;
            right: 0;
            text-align: center;

            p:nth-child(1) {
                font-size: 16px;
                font-family: "Montserrat-Medium";
                opacity: 0.5;
            }

            h1 {
                font-family: "Montserrat-Medium";
                font-size: 56px !important;
                margin-bottom: 80px;
                text-transform: uppercase;
            }
        }
    }

    .profile-wrap {
        padding: 20px 40px;
    }

    .profile-sec {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        border: 1px solid #747272;
        margin: 20px 0;
        border-radius: 20px;
        flex-wrap: wrap;

        .pro {
            padding: 20px;

            img {
                max-width: 100%;
            }
        }
        .btn-pro {
            padding: 20px;
            display: flex;
            justify-content: center;
            button {
                background: #fff;
                border: 1px solid #bfbbbb;
                font-size: 12px;
                border-radius: 4px;
                padding: 5px 10px;
                font-family: "Montserrat-Light";
                margin-right: 20px;
            }
        }

        .pro2 {
            padding-left: 70px;

            h2 {
                font-family: "Lato-Regular";
                font-size: 60px;
            }

            p {
                display: flex;
                align-items: center;
                font-family: "Lato-Regular";
                font-size: 20px;
                margin-left: 20px;

                img {
                    margin-right: 10px;
                }
            }

            button {
                border: 0;
                background: #585858;
                padding: 10px 54px;
                border-radius: 20px;
                color: #fff;
                font-family: "Montserrat-Medium";
                font-size: 16px;
                margin-left: 20px;
                margin-top: 15px;
            }
        }
    }

    .profile-sec2 {
        border: 1px solid #747272;
        margin: 20px 0;
        border-radius: 20px;
        padding: 23px;

        p {
            text-align: center;
            width: 100%;
            font-family: "Roboto-Medium";
            font-size: 20px;
            color: #585858;
        }
    }

    .profile-sec3 {
        button {
            border: 1px solid #747272;
            background: #fff;
            padding: 58px 54px;
            border-radius: 20px;
            color: #585858;
            width: 100%;
            margin-top: 20px;
            font-family: "Lato-Regular";
            font-size: 24px;
            text-transform: uppercase;
        }
    }

    .no-pad {
        padding: 0;
    }

    .tab-wraper {
        border: 1px solid #747272;
        margin: 0;
        //    padding: 0 15px;
        border-radius: 15px;

        .profile-tab {
            border: 1px solid hsl(0, 1%, 45%);
            border-radius: 15px 0 0 0px;
            overflow: hidden;
            border-bottom: 0;
            border-top: 0;
            border-left: 0;

            ul li {
                border-bottom: 1px solid #dadada;
                padding: 6px 0px;
                padding-left: 20px;
                font-family: "Roboto-Light";
                font-size: 16px;
                border-radius: 0 0 0 0;
                display: flex;
                align-items: center;
                cursor: pointer;

                img {
                    margin-right: 20px;
                }
            }

            ul li:last-child {
                border-bottom: none;
            }

            .tab-active {
                background-color: #dadada;
            }
        }

        .profile-content {
            // border: 1px solid #747272;
            // border-radius: 0 15px 15px 0;
        

            h2 {
                font-family: "Lato-Light";
                font-size: 18px;
                background-color: #dadada;
                text-align: center;
                border-radius: 0 15px 0 0;
                padding: 5px 0;
                border-bottom: 1px solid #747272;
            }

            .form-wrapper {
                padding: 57px 20px 52px 30px;
                position: relative;

                .form-sec {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    font-family: "Lato-Light";
                    font-size: 16px;

                    &:nth-child(1) p {
                        margin-left: 51px;
                        margin-bottom: 5px;
                    }

                    &:nth-child(2) input[type="text"] {
                        margin-left: 20px;
                    }

                    &:nth-child(3) input[type="text"] {
                        margin-left: 23px;
                    }

                    &:nth-child(4) input[type="text"] {
                        margin-left: 34px;
                    }

                    &:nth-child(5) input[type="text"] {
                        margin-left: 33px;
                        width: 300px;
                    }
                }

                button {
                    background: #585858;
                    border: 0;
                    border-radius: 4px;
                    color: #fff;
                    padding: 5px 33px;
                    margin-top: 26px;
                    margin-left: 95px;
                    font-family: "Montserrat-Medium";
                    font-size: 16px;
                }
            }
        }
    }

    @media only screen and (max-width: 1400px) {
        .account-detail {
            .profile-sec .pro2 h2 {
                font-size: 36px;
            }

            .profile-sec .pro2 {
                padding-left: 0px;
            }
        }

        .account-detail .profile-sec .pro2 button {
            padding: 10px 33px;
        }

        .account-detail .profile-sec .pro img {
            max-width: 90%;
        }

        .account-detail .profile-sec3 button {
            padding: 51px 27px;
        }

        .account-detail .profile-sec2 {
            padding: 52px 23px;
        }

        .account-detail .profile-sec .pro {
            padding: 20px 0;
        }
    }

    @media only screen and (max-width: 900px) {
        .account-detail .tab-wraper {
            margin-top: 30px;
        }

        .account-detail .tab-wraper .profile-tab {
            border-radius: 15px 15px 0 0px;
            border-right: 1px solid #969494;
        }

        .account-detail .tab-wraper .profile-content {
            border: 1px solid #747272;
            border-radius: 15px;
            overflow: hidden;
        }

        .profile-sec2 img {
            margin: 0 auto;
        }
    }

    @media only screen and (max-width: 600px) {
        .account-detail .profile-sec .pro {
            padding: 20px 0 20px 20px;
        }

        .account-detail .profile-sec .pro2 button {
            padding: 10px 14px;
        }

        .profile-sec2 {
            img {
                margin: 0 auto;
            }
        }

        .account-detail .tab-wraper {
            margin-top: 30px;
        }

        .account-detail .tab-wraper .profile-tab {
            border-radius: 15px 15px 0 0px;
            border-right: 1px solid #969494;
        }

        .account-detail .tab-wraper .profile-content {
            border: 1px solid #747272;
            border-radius: 15px;
            overflow: hidden;
        }

        .account-detail .profile-sec .pro2 {
            padding-left: 0px;
            text-align: center;
            width: 100%;
        }

        .account-detail .profile-sec .pro2 p {
            margin-left: 72px;
        }

        .account-detail .profile-sec .pro2 button {
            margin-bottom: 20px;
        }

        .account-detail .profile-sec .pro img {
            max-width: 100%;
        }
    }

    .account-detail .tab-wraper .profile-tab a {
        color: #212529;
    }

    /* METAWHEEL PAGE */

    .meta-cont {
        margin-top: -50px;
        overflow: hidden;
    }
    .meta-form-cont {
        background-color: #fff;
        border: 1px solid #d5d5d5;
        width: 300px;
    }

    .viewOptCont {
        position: absolute;
        right: 30px;
        top: 50px;
    }
    .meta-wheel {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        margin-top: 0;
        height: 650px;
    }
    .optLink {
        margin-left: 10px;
    }
    .optIcon {
        width: 20px;
        height: 20px;
        margin: 10px;
        color: #212529;
    }
    .metaBGImg {
        margin-top: 50px;
        width: 500px;
        height: 500px;
    }
    .logoz-cont {
        width: 500px;
        height: 600px;
        border-radius: 500px;
        position: absolute;
        top: 60px;
    }
    .logoz-link {
        display: block;
        /* background-color: red; */
        width: 90px;
        height: 90px;
    }
    .logoz {
        width: inherit;
        height: inherit;
    }
    .logoz:hover {
        box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
        transition: box-shadow 0.3s;
        width: inherit;
        height: inherit;
        border-radius: 50%;
        cursor: pointer;
    }
    .sslogo {
        margin-left: 110px;
        margin-top: -20px;
    }
    .uslogo {
        margin-top: -110px;
        margin-left: 250px;
    }
    .mslogo {
        margin-top: -30px;
        margin-left: 380px;
    }
    .mwlogo {
        margin-top: 30px;
        margin-left: 450px;
    }
    .hclogo {
        margin-top: 40px;
        margin-left: 450px;
    }
    .wslogo {
        margin-top: 30px;
        margin-left: 390px;
    }
    .vfhlogo {
        margin-top: -10px;
        margin-left: 250px;
    }
    .bclogo {
        margin-top: -110px;
        margin-left: 90px;
    }
    .vplogo {
        margin-top: -185px;
        margin-left: -15px;
    }
    .sgnlogo {
        margin-top: -225px;
        margin-left: -45px;
    }
    .hcnlogo {
        margin-top: -215px;
        margin-left: -10px;
    }

    .form-cont {
        width: 325px;
        border: 1px solid #d5d5d5;
        display: inline-block;
        margin-top: 30px;
        margin-left: 60px;
    }
}

@media only screen and (max-width: 959px) {
    .account-detail .tab-wraper .MuiGrid-container {
        display: block;
    }
}

