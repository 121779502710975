.account-detail .banner-secss {
    position: relative;
}
.account-detail .banner-secss img {
    height: 250px !important;
    width: 100%;
    object-fit: cover;
}
.account-detail .banner-secss::before {
    background-color: #282727 8e;
    content: '';
    display: block;
    height: 250px !important;
    position: absolute;
    width: 100%;
}
.account-detail .banner-secss .content-sec {
    position: absolute;
    width: unset !important;
    color: #fff;
    top: 125px !important;
    left: 0;
    right: 0;
    text-align: center;
}
.account-detail .banner-secss .content-sec p:nth-child(1) {
    font-size: 16px;
    font-family: 'Montserrat-Medium';
    opacity: 0.5;
}
.account-detail .banner-secss .content-sec h1 {
    font-family: 'Montserrat-Medium';
    font-size: 56px !important;
    margin-bottom: 80px;
    text-transform: uppercase;
}
.account-detail .profile-sec {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    border: 1px solid #747272;
    margin: 20px 0;
    border-radius: 20px;
}
.account-detail .profile-sec .pro {
    padding: 20px;
    width: 232px; /* Or any specific width */
    aspect-ratio: 1 / 1; /* Ensures the container maintains a 1:1 aspect ratio */
    overflow: hidden; 
    margin-bottom: 10px;
}
.account-detail .profile-sec .pro img {
    max-width: 100%;
    height: 100%;
    aspect-ratio: 1 / 1;
    border: solid 1px transparent;
    border-radius: 10px;
    object-fit: cover;
}
.account-detail .profile-sec .pro .btn-pro {
    display: flex;
    justify-content: left;
    align-items: center;
    gap: 10px;
}
.account-detail .profile-sec .pro .btn-pro button {
    background: #fff;
    border: 1px solid #bfbbbb;
    font-size: 12px;
    border-radius: 4px;
    padding: 5px 10px;
    font-family: 'Montserrat-Light';
    margin-right: 20px;
}
.account-detail .profile-sec .pro2 {
    padding: 15px;
    margin-bottom: 10px; 
    text-align: center;
}
.account-detail .profile-sec .pro2 .userName {
    text-align: left;
    font-size: 2.5rem;
}
.account-detail .profile-sec .pro2 p {
    display: flex;
    align-items: center;
    font-family: 'Lato-Regular';
    font-size: 20px;
    margin-left: 20px;
}
.account-detail .profile-sec .pro2 p img {
    margin-right: 10px;
}
.account-detail .profile-sec .pro2 button {
    border: 0;
    background: #585858;
    padding: 10px 54px;
    border-radius: 20px;
    color: #fff;
    font-family: 'Montserrat-Medium';
    font-size: 16px;
    margin-left: 20px;
    margin-top: 15px;
}
.account-detail .profile-sec2 {
    border: 1px solid #747272;
    margin: 20px 0;
    border-radius: 20px;
    padding: 23px;
    display: flex;
    flex-direction: column;
}
.account-detail .profile-sec2 p {
    text-align: center;
    width: 100%;
    font-family: 'Roboto-Medium';
    font-size: 20px;
    color: #585858;
}
.account-detail .profile-sec3 button {
    border: 1px solid #747272;
    background: #fff;
    padding: 58px 54px;
    border-radius: 20px;
    color: #585858;
    width: 100%;
    margin-top: 20px;
    font-family: 'Lato-Regular';
    font-size: 24px;
    text-transform: uppercase;
}
.account-detail .no-pad {
    padding: 0;
}

.account-detail .profile-sec .btn-pro {
    display: flex;
    justify-content: left !important;
    gap: 10px;
    width: 100%;
}

@media (min-width: 1150px) {
    .account-detail .profile-sec {
        flex-direction: row; 
        align-items: flex-start; 
    }

    .account-detail .profile-sec .pro,
    .account-detail .profile-sec .pro2 {
        margin-right: 20px; 
    }

    .account-detail .profile-sec .btn-pro {
        flex: 1 1 100%; 
        margin-top: 20px; 
    }
}

@media (max-width: 1230px) and (min-width: 617px) {
    .account-detail .profile-sec {
        flex-direction: column; 
        align-items: center; 
    }

    .account-detail .profile-sec .pro2 {
        padding-left: 0 !important;
        margin-left: 0 !important; 
        text-align: center;
    }

    .account-detail .profile-sec .btn-pro {
        justify-content: center !important;
    }
}

@media (max-width: 380px) {
    .account-detail .profile-wrap {
        padding: 20px !important;
        margin-left: -10px !important;
    }
}

@media (max-width: 616px) {
    .account-detail .profile-sec .pro2 {
        margin-left: -70px !important; 
        text-align: center;
    }
}